.container {
    background-color: #121212;
    padding: 32px;
    background-image:
    radial-gradient(white, rgba(255,255,255,.2) 2px, transparent 5px),
    radial-gradient(white, rgba(255,255,255,.15) 1px, transparent 5px),
    radial-gradient(white, rgba(255,255,255,.1) 2px, transparent 5px),
    radial-gradient(rgba(255,255,255,.4), rgba(255,255,255,.1) 2px, transparent 5px);
    background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px; 
    background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.white {
    color: white;
}