$primary: #BB86FC;
$card_primary: #373140;
$secondary: #03DAC5;
$bg: #121212;

.App {
  text-align: center;
  & .bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: -2;
    background: $bg;
  }
  & .bg-path {
    width: 100vw;
    height: 100vh;
    background: $bg;
    background: $primary;
    clip-path: polygon(50% 0, 100% 0, 100% 50%, 50% 100%, 0 100%, 0 50%);
    position: absolute;
    z-index: -1;
  }
}

.logo {
  height: 150px;
  pointer-events: none;
}

.header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 1vmin);
  color: white;
  & .right-side {
    display: flex;
    & .select {
      background-color: transparent;
      border-color: transparent;
      outline: none;
    }
    & .discord {
      width: 24px;
      height: 24px;
      color: $bg;
      margin-right: 1em;
      cursor: pointer;
      padding-left: 8px;
    }
  }
}

.footer {
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  font-weight: bold;
  color: black;
  background: repeating-linear-gradient(
  -45deg,
  $primary,
  $primary 10px,
  white 10px,
  white 20px
)
}

.timeline {
  padding: 2em;
  background-color: $bg;
}

.metadata {
  color: #888888;
  font-style: italic;
}

.title {
  color: $primary;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: .2em;
  font-size: .85em;
}

.contact {
  padding: 0 2em;
  & .contact-card {
    padding: 2em 0;
    border-radius: 8px;
    background-color: $bg;
    color: white;
    & .contact-line {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-decoration: unset;
      padding-top: 1em;
      font-size: 18px;
    }
  }
}

.mail-icon {
  width: 24px;
  height: 24px;
  color: $secondary;
}

@media (prefers-reduced-motion: no-preference) {
  .logo, .bg-path, .discord, .select {
    animation: fadeTop 1s ease-out forwards;
  }
}

@keyframes fadeTop {
  from {
      opacity: 0;
      transform: translateY(-100%)
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateY(0%)
  }
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 0 1em;
    & .right-side {
      & .discord {
        width: 32px;
        height: 32px;
        padding-left: 16px;
      }
    }
  }
  .contact-card {
    width: 60%;
    margin: auto;
  }
}

@media only screen and (min-width: 992px) {
  .header {
    padding: 0 2em;
  }
}

@media only screen and (min-width: 1200px) {
  .App-header {
    & .discord {
      width: 40px;
      height: 40px;
    }
  }
}