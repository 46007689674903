.home {
    color: white;
    display: -ms-grid;
    display: grid;
    text-align: center;
    padding: 2em;
}

.store-buttons {
    display: -ms-grid;
    display: grid;
    place-items: center;
}

.typing-container {
    height: 120px;
}

// class  name in library
.Typewriter {
    font-size: 32px;
    font-weight: bold;
    font-family: Arial;
    text-shadow: 1px 2px 2px #121212;
}

.main-desc {
    display: none;
}

.phone {
    background-color: #121212;
    width: 300px;
    height: 600px;
    border-radius: 32px;
    border: 1px solid #cccccc;
    box-shadow: -5px 5px 10px rgba(0,0,0,0.5);
}

.back-phone-position {
    display: none;
    position: absolute;
    right: 5%;
    bottom: -20px;
    z-index: -1;
}

.detail-img {
    width: 90%;
    height: 90%;
}

.fallback {
    position: absolute;
    width: 90%;
    height: 90%;
    z-index: 9;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.google-button {
    cursor: pointer;
    width: 180px;
}

.apple-button {
    cursor: pointer;
    height: 50px;
}

.content-left {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.content-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (prefers-reduced-motion: no-preference) {
    .phone {
      animation: fadeBottom 1s ease-in-out forwards;
    }

    .content-left {
        animation: simpleFade 1s ease-in-out forwards;
    }
  }

@keyframes simpleFade {
    from {
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeBottom {
    from {
        opacity: 0;
        transform: translateY(200%)
    }
    50% {
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: translateY(0%)
    }
  }

@media only screen and (min-width: 768px) {
    .home {
        height: 100vh;
    }

    .store-buttons {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 50% auto;
        grid-template-columns: 50% auto;
    }

    .typing-container {
        height: unset;
    }

    .main-desc {
        display: unset;
        font-weight: bold;
        font-size: 18px;
        max-width: 60%;
    }
}

@media only screen and (min-width: 992px) {
    .home {
        height: 100vh;
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 66% auto;
        grid-template-columns: 66% auto;
        background: unset;
        padding: 0 2em;
    }

    .Typewriter {
        font-size: 3em;
    }
}

@media only screen and (min-width: 1200px) {
    .home {
        padding: 0 4em;
    }
}

@media only screen and (min-width: 1550px) {
    .content-right {
        justify-content: flex-start;
    }
    .back-phone-position {
        display: unset;
    }
}