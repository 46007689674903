$primary: #BB86FC;
$secondary: #03DAC5;
$bg: #121212;

.timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 40px 0;
}

.timeline-container::after {
    background-color: $primary;
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
}

.timeline-item {
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin: 10px 0;
    width: 50%;
}

.timeline-item:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-right: 0;
    & .timeline-item-content {
        align-items: flex-start;
        text-align: left;
        margin-left: 30px - 5px; // normal padding minus circle border size
    }
}

.timeline-item-content {
    background-color: $bg;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px;
    position: relative;
    width: 400px;
    max-width: 70%;
    text-align: right;
    margin-right: 30px - 5px; // normal padding minus circle border size
    color: white;
}

.timeline-item-content time {
    text-transform: uppercase;
    font-weight: bold;
    color: $secondary;
    letter-spacing: .2em;
    font-size: .95em;
}

.timeline-item-content p {
    line-height: 24px;
    margin: 8px 0;
    max-width: 250px;
    font-size: .95em;
}

.timeline-item-content .circle {
    background-color: $secondary;
    border: 5px solid $bg;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 10px);
    right: -40px;
    width: 20px;
    height: 20px;
    z-index: 100;
}

.timeline-item:nth-child(odd) .timeline-item-content .circle {
    right: auto;
    left: -40px;
}

@media only screen and (max-width: 1023px) {
    .timeline-item-content {
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .timeline-item-content,
    .timeline-item:nth-child(odd) .timeline-item-content {
        padding: 15px 0px;
        text-align: center;
        align-items: center;
    }
}