$primary: #BB86FC;
$secondary: #03DAC5;
$bg: #121212;

.features {
    position: relative;
    padding: 2em;
    text-align: center;
    margin-bottom: 2em;
}

.features::before {
    content: "";
    position: absolute;
    width: 250px;
    height: 150px;
    background: $primary;
    top: 0;
    left: 0;
    z-index: -1;
}

.features::after {
    content: "";
    position: absolute;
    width: 250px;
    height: 150px;
    background: $secondary;
    bottom: 0;
    right: 0;
    z-index: -1;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.features-container ul li {
    padding: 2em;
    margin-bottom: .5em;
}

.features-title {
    text-transform: uppercase;
    font-weight: bold;
    color: $bg;
    letter-spacing: .2em;
    font-size: .85em;
}

.features-container ul li .icon-container {
    height: 24px;
    width: 24px;
    display: -ms-grid;
    display: grid;
    place-content: center;
    margin: 0 auto;
}

.features-icon {
    width: 32px;
    height: 32px;
}

.features-desc {
    display: none;
}

@media only screen and (min-width: 768px) {
    .features ul {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: (auto)[2];
        grid-template-columns: repeat(2, auto);
        grid-gap: 1em;
    }
}


@media only screen and (min-width: 992px) {
    .features ul {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: (auto)[4];
        grid-template-columns: repeat(4, auto);
        grid-gap: 1em;
    }
}

@media only screen and (min-width: 1200px) {
    .features {
        padding: 2em 4em;
    }
}