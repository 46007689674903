$primary: #BB86FC;

.faq {
    padding: 2em;
}

.title {
    color: $primary;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: .2em;
    font-size: .85em;
}

.questions {
    text-align: left;
}

.answer {
    text-align: justify;
}

@media only screen and (min-width: 768px) {

    .questions {
        width: 60%;
        margin: auto;
    }

}